import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle/PageTitle";
import ContentBlock from "../components/LandingContentBlock/ContentBlock";
import SEO from "../components/SEO/SEO";

import "./scss/about.scss";

export default () => (
  <Layout>
    <SEO
      title="Zest 4 Maths | About | Why Choose Us for Maths Tuition"
      description="Our maths tutors take pride in providing personalised tuition to individuals or small groups: Face-
to-Face or Online Tuition to Workshops & Online Courses."
    />
    <ContentBlock class="no-margin">
      <PageTitle title="Our Story">
        Zest 4 Maths is a family-owned business that has been operating since
        2015. We are based in Lincoln, just outside Christchurch. Our friendly
        and dedicated maths tutors take pride in providing personalised and
        enjoyable tuition to individuals or small groups; from Face-to-Face or
        Online Tuition to Workshops and Online Courses.
      </PageTitle>
    </ContentBlock>
    <ContentBlock>
      <div className="content-container--single">
        <div className="content-container__item">
          <div className="content-container__item__header-container">
            <div className="content-container__item__header-container__header">
              Students Seek Our Help For Many Reasons
            </div>
            <div className="content-container__item__header-container__emoji">
              📚
            </div>
          </div>
          <p>
            Primary aged children can get that extra boost to help them
            understand maths concepts, giving them confidence and the ability to
            catch up if they’re falling behind. For older students studying
            towards New Zealand NCEA Levels 1-3 and the Cambridge International
            Examinations (IGCSE), we provide Revision Workshops and Exam
            Preparation in Terms 3 & 4. To keep things on track, we can also
            provide catch up sessions for you if you’ve been out of the
            classroom. Zest 4 Maths can assist if you need to prepare for the
            Police or the New Zealand Defence Force (Navy, Army and Airforce)
            entrance aptitude tests or any other course or employment requiring
            mathematics. We look forward to helping you soon.
          </p>
        </div>
      </div>
    </ContentBlock>

    <ContentBlock class="no-margin">
      <div className="content-container--right">
        <div className="content-container__item--sm">
          <div className="content-container__item--sm__img">
            <img src={"/karen.jpg"} />
          </div>
        </div>
        <div className="content-container__item">
          <div className="content-container__item__header-container">
            <div className="content-container__item__header-container__header">
              Meet Karen
            </div>
            <div className="content-container__item__header-container__emoji">
              ✏️
            </div>
          </div>
          <p>
            My journey towards teaching started when my son started school and
            my daughter started preschool. I went into my children’s school as a
            volunteer to support students with their mathematics learning. I
            loved it and the feedback from the students, their parents and their
            teacher was awesome. This inspired me to do a mathematics degree. I
            thoroughly enjoyed being back in the classroom learning myself and
            as usual put in 100% effort to be awarded a Bachelor of Science
            First Class Honours degree in Mathematics. During this time I also
            did private mathematics tuition for primary and secondary school
            students.
          </p>
          <p>
            A week after graduating, my family and I moved to New Zealand to
            start a new chapter of our lives. I graduated from the University of
            Otago with a Graduate Diploma of Teaching in Secondary Education and
            was also awarded a Certificate for Teaching Practice Excellence by
            the Academic Committee. I taught at Aparima College, Riverton and
            was the Head of the mathematics department for 8 years.
          </p>
          <p>I am passionate in helping students to:</p>
          <ul>
            <li>Understand the mathematics they are learning</li>
            <li>Tackle new topics with confidence and enjoyment</li>
            <li>Exceed their expectations</li>
          </ul>
        </div>
      </div>
    </ContentBlock>
  </Layout>
);
